define("discourse/plugins/discourse-user-response-times/discourse/templates/connectors/user-card-metadata/discourse-user-response-times", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <UserResponseTime @user={{this.user}} />
  */
  {
    "id": "8rmocn4y",
    "block": "[[[8,[39,0],null,[[\"@user\"],[[30,0,[\"user\"]]]],null]],[],false,[\"user-response-time\"]]",
    "moduleName": "discourse/plugins/discourse-user-response-times/discourse/templates/connectors/user-card-metadata/discourse-user-response-times.hbs",
    "isStrictMode": false
  });
});
define("discourse/plugins/discourse-user-response-times/discourse/components/user-response-time", ["exports", "@glimmer/component", "discourse-common/helpers/d-icon", "@ember/component", "@ember/template-factory"], function (_exports, _component, _dIcon, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class UserResponseTime extends _component.default {
    get responseTime() {
      var t = this.args.user.response_time_seconds || 86400;
      return I18n.t("user_response_times.user_card", {
        time: moment.duration(t * 1000).humanize()
      });
    }
    get mustShow() {
      return this.args.user.response_time_seconds > 0;
    }
    static #_ = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        {{#if this.mustShow}}
          {{icon 'far-clock'}} {{ this.responseTime }}
        {{/if}}
      
    */
    {
      "id": "iHJLA7RD",
      "block": "[[[1,\"\\n\"],[41,[30,0,[\"mustShow\"]],[[[1,\"      \"],[1,[28,[32,0],[\"far-clock\"],null]],[1,\" \"],[1,[30,0,[\"responseTime\"]]],[1,\"\\n\"]],[]],null],[1,\"  \"]],[],false,[\"if\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-user-response-times/discourse/components/user-response-time.js",
      "scope": () => [_dIcon.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = UserResponseTime;
});